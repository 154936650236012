import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from "vuex-persist";

const localStorage = new VuexPersist({
  key: "vuex",
  storage: window.localStorage,
  reducer: (state) => ({
    currentCategory: state.currentCategory,
  }),
});

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [localStorage.plugin],
  state: {
    currentCategory: {},
  },
  mutations: {
    setCurrentCategory(state,category){
      state.currentCategory = category;
    }
  },
  actions: {
    updateCurrentCategory({ commit }, category) {
      commit('setCurrentCategory', category);
    }
  },
  modules: {
  },
  getters: {
    getCurrentCategory(state) {
      return state.currentCategory;
    }
  }
})
