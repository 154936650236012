<template>
  <div class="app">
    <v-app>
      <!-- <HeaderMain></HeaderMain> -->
      <v-main>
        <router-view />
      </v-main>
      <FooterMain></FooterMain>
    </v-app>
  </div>
</template>

<style>
.app {
  color: #000;
}
</style>
<script>
// import HeaderMain from "@/components/HeaderMain.vue";
import FooterMain from "@/components/FooterMain";

export default {
  name: "App",
  components: {
    // HeaderMain,
    FooterMain,
  },
};
</script>
