import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: require("./locales/en.json"),
  ru: require("./locales/ru.json"),
};

const i18n = new VueI18n({
  locale: "ru", // Установите начальную локаль по умолчанию
  messages,
});

export default i18n;
