import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      breadcrumb: 'Главная' // Метка для главной страницы
    },
  },

  {
    path: "/distributor",
    name: "Distributor",

    component: () => import("../views/Distributor.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/categories/:categoryId",
    name: "Categories",
    component: () => import("../views/Categories.vue"),
    meta: {
      breadcrumb: 'Конфеты и драже' // Метка для страницы Конфеты и драже
    },
    // beforeEnter(to, from, next) {
    //   setTimeout(() => {
    //     next(vm => {
    //       vm.scrollToSavedPosition();
    //     });
    //   });
    // },
    children: [
      // {
      //   path: '/categories/:categoryId/product/:productId',
      //   name: 'ProductPage',
      //   component: () => import('../views/ProductPage.vue'),
      //   meta: {
      //     breadcrumb: 'Шоколадная Мечта' // Метка для страницы Шоколадная Мечта
      //   },
      // }
    ]
    // props: true,
  },
  {
    path: '/categories/:categoryId/product/:productId',
    name: 'ProductPage',
    component: () => import('../views/ProductPage.vue'),
    meta: {
      breadcrumb: 'Шоколадная Мечта' // Метка для страницы Шоколадная Мечта
    },
  },
  // {
  //   path: '/product',
  //   name: 'ProductPage',
  //   component: () => import('../views/ProductPage.vue'),
  //   meta: {
  //     breadcrumb: 'Шоколадная Мечта' 
  //   },
  // },
  {
    path: '/didyouknow',
    name: 'Didyouknow',
    component: () => import('../views/Didyouknow.vue'),

  },
  {
    path: '/didyouknowproduct/:postId',
    name: 'Didyouknowproduct',
    component: () => import('../views/DidyouknowProduct.vue'),

  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),

  }
];

const router = new VueRouter({
  mode: "history", // Устанавливаем режим "history"
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  // Прокрутка на верх страницы
  window.scrollTo(0, 0)
  next()
})

export default router;
