import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/global.css";
import i18n from "./i18n";
import axios from "axios";
import VueParallaxJs from 'vue-parallax-js';
import YmapPlugin from 'vue-yandex-maps';
require("dotenv").config();
import "./assets/fonts/fonts.css"



axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
axios.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';
  config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization';
  config.headers['CurrentLanguage'] = i18n.locale;
  return config;
});
const settings = {
  apiKey: 'ca94b5ad-f0d0-4ee5-a6c6-62e078cdd1f0',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
};
Vue.use(YmapPlugin, settings);


Vue.config.productionTip = false;
Vue.use(VueParallaxJs);

Vue.prototype.$http = axios;
Vue.prototype.$store = store;
// Vue.prototype.appname = "RdProof";

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
