<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <div class="bg-top-mobile">
        <HeaderMain></HeaderMain>

        <!-- <div class="distributor-container-mobile" style="">
          <p class="title-text-mobile">Accumsan</p>
          <p class="title-text-mobile">viverra</p>
          <p class="title-text-mobile">egestas</p>
          <p class="desc-text-mobile">
            Accumsan viverra egestas proin dolor tincidunt. Vitae sapien sit
            faucibus morbi praesent metus nibh tempor ut. Vel facilisis pharetra
            nisi nulla montes senectus.
          </p>
        </div> -->
      </div>
      <div class="about-us-container-mobile" style="">
        <div class="top-container-mobile">
          <h2 class="about-us-title-mobile">{{ $t("about-us") }}</h2>
          <div class="about-us-desc-mobile-container">
            <p class="about-us-desc-mobile">
              {{ $t("about-us-text-1") }}
            </p>
          </div>
        </div>
        <div class="cards-container-mobile">
          <div class="card-1-mobile">
            <img
              class="card-img-1-mobile"
              src="../assets/img/card-1.svg"
              alt=""
            />
            <p class="card-desc-mobile">
              {{ $t("about-us-card-text-1") }}
            </p>
          </div>

          <div class="card-1-mobile">
            <img
              class="card-img-1-mobile"
              src="../assets/img/card-2.svg"
              alt=""
            />
            <p class="card-desc-mobile-2">
              {{ $t("about-us-card-text-2") }}
            </p>
          </div>

          <div class="card-1-mobile">
            <img
              class="card-img-1-mobile"
              src="../assets/img/card-3.svg"
              alt=""
            />
            <p class="card-desc-mobile-3">
              {{ $t("about-us-card-text-3") }}
            </p>
          </div>
        </div>
      </div>
      <div class="bot-bg-container-mobile">
        <img
          class="main-bg-bot-mobile"
          src="../assets/img/bot-bg-mobile.png"
          alt=""
        />
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="bg-top">
        <HeaderMain></HeaderMain>

        <!-- <div class="distributor-container" style="">
          <div class="text-container">
            <p class="title-text">Accumsan viverra egestas</p>
            <p class="desc-text">
              Accumsan viverra egestas proin dolor tincidunt. Vitae sapien sit
              faucibus morbi praesent metus nibh tempor ut. Vel facilisis
              pharetra nisi nulla montes senectus.
            </p>
          </div>
        </div> -->

        <!-- <FooterMain></FooterMain> -->
      </div>
      <div class="about-us-container" style="">
        <div class="top-container">
          <h2 class="about-us-title">{{ $t("about-us") }}</h2>
          <p class="about-us-desc">
            {{ $t("about-us-text-1") }}
          </p>
        </div>
        <!-- <div class="cards-container-2">
          <div class="card">
            <img class="card-img-1" src="../assets/img/card-1.svg" alt="" />
          </div>
          <div class="card">
            <img class="card-img-2" src="../assets/img/card-2.svg" alt="" />
          </div>
          <div class="card">
            <img class="card-img-3" src="../assets/img/card-3.svg" alt="" />
          </div>
        </div> -->
        <div class="cards-container">
          <div class="card-1">
            <img class="card-img-1" src="../assets/img/card-1.svg" alt="" />
            <p class="card-desc-1">
              {{ $t("about-us-card-text-1") }}
            </p>
          </div>
          <div class="card-2">
            <img class="card-img-2" src="../assets/img/card-2.svg" alt="" />
            <p class="card-desc-2">
              {{ $t("about-us-card-text-2") }}
            </p>
          </div>
          <div class="card-3">
            <img class="card-img-3" src="../assets/img/card-3.svg" alt="" />
            <p class="card-desc-3">
              {{ $t("about-us-card-text-3") }}
            </p>
          </div>
        </div>
      </div>
      <div class="bot-bg-container">
        <img class="main-bg-bot" src="../assets/img/main-bg-bot.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMain from "@/components/HeaderMain.vue";

// import FooterMain from "@/components/FooterMain";

export default {
  name: "Home",
  data: () => ({
    bannerImagePath: "",
  }),
  components: {
    HeaderMain,

    // FooterMain,
  },
  methods: {
    initializeBanner(){
      this.$http
        .get(`/api/Banner/GetList`)
        .then((response) => {
          console.log(response)
          this.bannerImagePath = process.env.VUE_APP_API_HOST + `/api/Image/GetResized?imageId=${response.data[0].images[0].id}&width=1920&height=831&isKeepAspectRatio=true`;
          console.log(this.bannerImagePath)
          const bgTopElements = document.querySelectorAll('.bg-top');
          //https://api.rdprooff.dragau.kz/api/Image/GetResized?imageId=${item.images[0].id}&width=304&height=282&isKeepAspectRatio=false`
         //https://api.rdprooff.dragau.kz/Image/bannerbase/08dbe66d-0926-4f88-85aa-292a8e14d1b2/08dbecce-ddcc-4f47-854a-22785e76e222.png
          bgTopElements.forEach((element) => {
            element.style.backgroundImage = `url("${this.bannerImagePath}")`;
          });

          // Установка фонового изображения для элементов с классом 'bg-top-mobile'
          const bgTopMobileElements = document.querySelectorAll('.bg-top-mobile');
          bgTopMobileElements.forEach((element) => {
            element.style.backgroundImage = `url("${process.env.VUE_APP_API_HOST + `/api/Image/GetResized?imageId=${response.data[0].images[0].id}&width=1320&height=831&isKeepAspectRatio=true`}")`;
          });
          // this.banner= response.data;
          // this.bannerName = this.banner.name;
          // this.selectedOptionState = this.banner.stateCode;
          // if (this.banner.images.length && this.banner.images[0]) {
          //   const randomParam = Math.random();
          //   this.uploadedImageLogo =
          //     process.env.VUE_APP_API_HOST +
          //     this.banner.images[0].imagePath +
          //     `?random=${randomParam}`;
          //   this.currentImageId = this.banner.images[0].id;
          //   console.log(this.uploadedImageLogo);
          // }
        })
        .catch((error) => {
          alert(error);
        });
    }
  },
  getImageUrl(imageId, width, height, isKeepAspectRatio) {
    return `${process.env.VUE_APP_API_HOST}/api/Image/GetResized?imageId=${imageId}&width=${width}&height=${height}&isKeepAspectRatio=${isKeepAspectRatio}`;
  },
  created(){
    this.initializeBanner();
  }
};
</script>

<style scoped>
.cards-container-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
}

.card-1-mobile {
  width: 100%;
  max-width: calc(50% - 10px); /* Две карточки в ряд до 653px ширины экрана */
  margin: 5px;
  text-align: center;
  box-sizing: border-box;
}

.card-1-mobile:nth-child(3n + 1) {
  clear: both; /* На новой строке после каждой третьей карточки */
}

@media (max-width: 1263px) {
  .card-desc-mobile {
    color: var(--01, #603725);
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-top: 26px; */
  }
  .card-desc-mobile-2 {
    color: var(--01, #603725);
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-top: 26px !important; */
  }
  .card-desc-mobile-3 {
    color: var(--01, #603725);
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 26px !important;
  }
  .card-img-1-mobile {
    width: 154px;
    height: 136px;
  }
  .card-1-mobile {
    width: 100%;
    max-width: calc(50% - 10px); /* Две карточки в ряд до 653px ширины экрана */
    margin: 5px;
    text-align: center;
    box-sizing: border-box;
  }
  .about-us-desc-mobile-container {
    text-align: center;
    max-width: 564px;
    margin: auto;
  }
}

@media screen and (max-width: 365px) {
  .about-us-title-mobile {
    color: var(--01, #603725);
    font-family: Montserrat;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: uppercase;
  }
}
@media (max-width: 653px) {
  .card-1-mobile {
    max-width: 100%; /* Одна карточка на всю ширину экрана при ширине менее 653px */
    clear: none; /* Не переносить на новую строку */
  }

  .card-desc-mobile-3 {
    margin-top: 0px;
    margin-bottom: 20px !important;
  }
  .card-desc-mobile-2 {
    margin-top: 20px;
  }
  .card-desc-mobile {
    margin-top: 18px;
    margin-bottom: 20px !important;
  }
}

.main-bg-bot-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bot-bg-container-mobile {
  width: 100%;
  height: 100%;
  /* position: relative; */
  overflow: hidden;
  display: flex;
}
.cards-container-mobile-3 {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 75px;
}
.cards-container-mobile-2 {
  text-align: center;
  margin-top: 75px;
}
.card-desc-mobile-3 {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 18px; */
}
.card-desc-mobile-2 {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 37px; */
}
.card-desc-mobile {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 26px; */
}
.cards-container-mobile {
  text-align: center;
  margin-top: 31px;
}
.about-us-desc-mobile {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 22px;
}
.about-us-title-mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}
.top-container-mobile {
  text-align: center;
}

.about-us-container-mobile {
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 31px;
}
.desc-text-mobile {
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  max-width: 361px;
}

.title-text-mobile {
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.distributor-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 32px;
}
.bg-top-mobile {
  width: 100%;
  height: 111vh;
  /* height: 100%; */
  /* background-image: url("../assets/img/top-bg-mobile.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* Стиль контейнера */
.bot-bg-container {
  width: 100%;
  height: 100%;
  /* position: relative; */
  overflow: hidden;
  display: flex;
}

/* Стиль изображения */
.main-bg-bot {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-desc-3 {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 40px;
}
.card-desc-2 {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 25px;
}
.card-desc-1 {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-3 {
  transition: transform 0.2s ease; /* Добавляем анимацию сглаживания для свойства transform */
}

.card-3:hover {
  transform: scale(1.1);
  cursor: pointer; /* Увеличиваем элемент при наведении в 1.1 раза (10% увеличение) */
}
.card-2 {
  transition: transform 0.2s ease; /* Добавляем анимацию сглаживания для свойства transform */
}

.card-2:hover {
  transform: scale(1.1);
  cursor: pointer; /* Увеличиваем элемент при наведении в 1.1 раза (10% увеличение) */
}

.card-1 {
  transition: transform 0.2s ease; /* Добавляем анимацию сглаживания для свойства transform */
}

.card-1:hover {
  transform: scale(1.1);
  cursor: pointer; /* Увеличиваем элемент при наведении в 1.1 раза (10% увеличение) */
}
.card-img-3 {
  width: 141px;
  height: 71px;
  margin-top: 25px;
}
.card-img-2 {
  width: 144px;
  height: 112px;
  /* margin-bottom: 38px; */
  /* margin-left: 295px;
  margin-right: 306px; */
}
.card-img-1 {
  width: 154px;
  height: 136px;
  /* margin-bottom: 26px; */
}
.card-3 {
  text-align: center;
  flex-direction: column;
  max-width: 414px;
  /* height: 150px; */
}
.card-2 {
  text-align: center;
  flex-direction: column;
  max-width: 416px;
  margin-left: 33px;
  margin-right: 33px;
  /* height: 150px; */
}
.card-1 {
  text-align: center;
  flex-direction: column;
  max-width: 406px;
}
.cards-container {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  /* align-items: center; */
}
.cards-container-2 {
  display: flex;
  justify-content: center;
  margin-top: 38px;
  align-items: center;
}
.top-container {
  max-width: 896px;
  margin: auto;
}
.about-us-desc {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.about-us-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 22px;
}
.about-us-container {
  /* margin-right: auto; */
  /* margin-left: auto; */
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 63px;
}
.distributor-container {
  display: flex;
  margin-top: 17.5925925926vh;
  width: 1350px;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
  justify-content: flex-start;

  align-items: flex-start;
}
.desc-text {
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title-text {
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 415px;
}
.bg-top {
  width: 100%;
  height: 76.9444444444vh;
  /* max-width: 1920px; */
  /* height: 100%; */
  /* background-image: url("../assets/img/main-bg-top.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.text-container {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  padding: 30px 27px;
  max-width: 532px;
  /* margin-top: 310px;
  margin-left: 332px; */
  backdrop-filter: blur(10.5px);
}
@media screen and (min-width: 576px) and (max-width: 1263px) {
  .bg-top-mobile {
    height: 80vh; /* Замените на желаемое значение высоты */
    /* max-width: 1263px;
    width: 100%; */
    /* background-image: url("../assets/img/bg-top-992px.png"); */
    /* https://api.rdprooff.dragau.kz/api/Image/GetResized?imageId=${item.images[0].id}&width=304&height=282&isKeepAspectRatio=false` */
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 768px) and (min-height: 1024px) {
  /* Задаем высоту картинки на фоне в vh (например, 50vh) */
  .bg-top-mobile {
    height: 68.9vh; /* Замените на желаемое значение высоты */
  }
  .main-bg-bot-mobile {
    height: 32.2vh;
  }
}
@media screen and (min-width: 1024px) and (min-height: 768px) {
  /* Задаем высоту картинки на фоне в vh (например, 50vh) */
  .main-bg-bot-mobile {
    height: 43.2vh;
    object-position: bottom;
  }
}
@media screen and (max-height: 500px) {
  .bg-top-mobile {
    width: 100%;
    height: 100vh;
    /* height: 100%; */
    /* background-image: url("../assets/img/bg-top-height.png"); */
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
  .main-bg-bot-mobile {
    height: 100vh;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }
}
</style>
