<template>
  <div class="footer-main">
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="footer-main" style="">
        <!-- position: relative; z-index: 100 -->
        <div class="footer-logo-container">
          <router-link to="/">
            <img
              class="footer-logo"
              src="../assets/img/logo-header.svg"
              alt="logo-footer"
            />
          </router-link>
        </div>
        <div class="footer-bottom-margin">
          <div class="footer-info">
            <div class="footer-content py-1">
              <img
                src="../assets/img/footer-pin.svg"
                class="footer-nav-img"
                alt="footer-nav-img"
              />
              <p class="footer-text">
                {{ $t("footer-adress") }}
              </p>
            </div>
            <div class="footer-content py-1">
              <img
                src="../assets/img/footer-phone.svg"
                class="footer-nav-img"
                alt="footer-nav-img"
              />
              <p class="footer-text">{{ $t("footer-tel") }}</p>
            </div>
            <div class="footer-content py-1">
              <img
                src="../assets/img/footer-mail.svg"
                class="footer-nav-img"
                alt="footer-nav-img"
              />
              <p class="footer-text">{{ $t("footer-email") }}</p>
            </div>
            <div class="footer-content-special py-1">
              <p class="footer-text-special">
                <img
                  src="../assets/img/footer-calendar.svg"
                  class="footer-nav-img"
                  alt="footer-nav-img"
                />{{ $t("footer-work-hours") }}
              </p>
              <span class="footer-span-special">{{
                $t("footer-day-off")
              }}</span>
            </div>
          </div>

          <div class="policy-container">
            <router-link to="/privacy" class="footer-text">{{
              $t("footer-policity")
            }}</router-link>
            <router-link to="/didyouknow" style="text-decoration: none">
              <p href="#" class="footer-text-underline" style="">
                {{ $t("footer-you-know") }}
              </p>
            </router-link>
          </div>

          <div class="call-back-container">
            <button class="btn-call" @click="openModalCallBack">
              {{ $t("footer-call-back") }}
            </button>
          </div>
        </div>
      </div>
      <v-dialog class="v-dialog-border" v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="application-title"
            >{{ $t("callback-modal-title") }}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="name" style="padding-bottom: 14px">
              <label>{{ $t("callback-label-1") }}</label>
              <input
                v-model="name"
                ref="nameTextarea"
                type="text"
                :placeholder="textareaPlaceholder"
                class="name-input"
              />
            </div>
            <div class="name" style="padding-bottom: 14px">
              <label>{{ $t("callback-label-2") }}</label>
              <span class="red-dot">*</span>
              <input
                v-model="phoneNum"
                ref="phoneNumTextarea"
                v-mask="'+7 (###) ###-##-##'"
                type="tel"
                placeholder=""
                class="name-input py-3"
              />
            </div>
            <div class="name">
              <label>{{ $t("callback-label-3") }}</label>
              <input
                v-model="email"
                ref="emailTextarea"
                type="text"
                placeholder=""
                class="name-input"
              />
            </div>
            <div class="text-warning d-flex mt-4">
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  id="consentCheckbox"
                  class="custom-checkbox"
                  v-model="consentChecked"
                />
                <span class="checkmark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </label>
              <p class="" @click="closeDialog">
                {{ $t("input-i-agree") }}
                <router-link to="/privacy" class="policy-text-mobile" >{{
                  $t("input-i-agree-2")
                }}</router-link>
              </p>
            </div>
            <button v-on:click="handleSubmit" class="btn-call-modal" :disabled="!isValid">
              {{ $t("callback-modal-button") }}
            </button>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="5000" class="btn-contaiener">
                  {{ this.message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                          Закрыть
                        </div>
                </template>
            </v-snackbar>
    </div>
    <div class="d-sm-block d-mb-block d-lg-none">
      <div class="footer-main-mobile" style="">
        <!-- position: relative; z-index: 100 -->
        <div class="footer-logo-container-mobile">
          <router-link to="/">
            <img
              class="footer-logo-mobile"
              src="../assets/img/logo-header.svg"
              alt="logo-footer"
            />
          </router-link>
        </div>
        <div class="footer-bottom-margin-mobile">
          <div class="first-column-mobile">
            <div class="footer-info-mobile">
              <div class="footer-content-mobile py-2">
                <img
                  src="../assets/img/footer-pin.svg"
                  class="footer-nav-img"
                  alt="footer-nav-img"
                />
                <p class="footer-text-mobile">
                  {{ $t("footer-adress") }}
                </p>
              </div>
              <div class="footer-content-mobile py-2">
                <img
                  src="../assets/img/footer-phone.svg"
                  class="footer-nav-img"
                  alt="footer-nav-img"
                />
                <p class="footer-text-mobile">{{ $t("footer-tel") }}</p>
              </div>
              <div class="footer-content-mobile py-2">
                <img
                  src="../assets/img/footer-mail.svg"
                  class="footer-nav-img"
                  alt="footer-nav-img"
                />
                <p class="footer-text-mobile">{{ $t("footer-email") }}</p>
              </div>
              <div class="footer-content-special-mobile py-2">
                <p class="footer-text-special-mobile">
                  <img
                    src="../assets/img/footer-calendar.svg"
                    class="footer-nav-img"
                    alt="footer-nav-img"
                  />{{ $t("footer-work-hours") }}
                </p>
                <span class="footer-span-special-mobile">{{
                  $t("footer-day-off")
                }}</span>
              </div>
            </div>
          </div>
          <div class="second-column-mobile">
            <div class="policy-container-mobile">
              <router-link to="/privacy" class="footer-text-mobile">{{
                $t("footer-policity")
              }}</router-link>
              <router-link to="/didyouknow">
                <p
                  href="#"
                  class="footer-text-underline-mobile"
                  style="margin-top: 14px"
                >
                  {{ $t("footer-you-know") }}
                </p>
              </router-link>
            </div>
            <div class="call-back-container-mobile">
              <button class="btn-call-mobile" @click="openModalCallBackMobile">
                {{ $t("footer-call-back") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <v-dialog
        class="v-dialog-border"
        v-model="showDialogMobile"
        max-width="343"
      >
        <v-card>
          <v-card-title class="application-title"
            >{{ $t("callback-modal-title") }}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialogMobile">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="name" style="padding-bottom: 14px">
              <label>{{ $t("callback-label-1") }}</label>
              <input
                v-model="name"
                type="text"
                :placeholder="textareaPlaceholder"
                class="name-input"
              />
            </div>
            <div class="name" style="padding-bottom: 14px">
              <label>{{ $t("callback-label-2") }}</label>
              <span class="red-dot">*</span>
              <input
                v-model="phoneNum"
                ref="phoneNumTextarea"
                v-mask="'+7 (###) ###-##-##'"
                type="tel"
                placeholder=""
                class="name-input py-3"
              />
            </div>
            <div class="name">
              <label>{{ $t("callback-label-3") }}</label>
              <input
                v-model="email"
                ref="emailTextarea"
                type="text"
                placeholder=""
                class="name-input"
              />
            </div>
            <div class="text-warning d-flex mt-4">
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  id="consentCheckbox"
                  class="custom-checkbox"
                  v-model="consentChecked"
                />
                <span class="checkmark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.6673 3.5L5.25065 9.91667L2.33398 7"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </label>
              <p class="">
                {{ $t("input-i-agree") }}
                <router-link class="policy-text-mobile" to="/privacy">{{
                  $t("input-i-agree-2")
                }}</router-link>
              </p>
            </div>
            <button v-on:click="handleSubmit" class="btn-call-modal" :disabled="!isValid">
              {{ $t("callback-modal-button") }}
            </button>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="5000" class="btn-contaiener">
                  {{ this.message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                          Закрыть
                        </div>
                </template>
            </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  name: "FooterMain",
  directives: { mask },
  data() {
    return {
      showDialog: false,
      showDialogMobile: false,
      name: "",
      phoneNum: "",
      email: "",
      isPlaceholderHidden: false,
      consentChecked: false,
      snackbar: false,
      message: ""
    };
  },
  watch: {
    // name() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("nameTextarea");
    //   });
    // },
    // phoneNum() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("phoneNumTextarea");
    //   });
    // },
    // email() {
    //   this.$nextTick(() => {
    //     this.adjustTextareaHeight("emailTextarea");
    //   });
    // },
  },
  methods: {
    redirectToProduct() {
      // Используем $router.push() для перенаправления на страницу /product
      this.closeDialog();
      this.closeDialogMobile();
      this.$router.push("/privacy");
    },
    handleSubmit() {
          //let message = `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`;
          let data = {
            EmailToId: "info@rdprooff.ru",
            EmailToName: "rdprooff.ru",
            EmailSubject: "Заявка на обратный звонок",
            EmailBody: 
            `
            <html>
              <body>
                <p><strong>Телефон:</strong> ${this.phoneNum}</p>
                <p style="color:black"><strong>Имя:</strong> ${this.name || "Не указано"}</p>
                <p style="color:black"><strong>Электронная почта:</strong> ${this.email || "Не указано"}</p>
                <p style="color:black">С уважением, <br> Команда сайта <a href="rdprooff.ru">rdprooff.ru</a></p>
              </body>
            </html>
             `
          }

          this.$http
            .post(`api/Email`,data, {
              headers: {
                // Host: 'api.rdprooff.ru',
                "Content-Type": 'application/json',
              }
              // body: {
              //   EmailToId: "dimon.cool2000@gmail.com",
              //   EmailToName: "Alex",
              //   EmailSubject: "TestEmail",
              //   EmailBody: `Телефон: ${this.tel}, Имя: ${this.name}, Комментарий: ${this.description}`
              // },
            })
            .then(() => {
              // this.resetModal();
              // this.modal = false;
              // this.$emit("changeModal", this.modal);
              // this.$emit("modalOk");
              this.closeDialog();
              this.closeDialogMobile();
              this.snackbar = true;
              this.message = "Заявка отправленна";
            })
            .catch((error) => {
              this.error = error;
              this.snackbar = true;
              this.message = "Ошибка в заявки";
            });
    },
    closeDialog() {
      this.showDialog = false;
      this.name = "";
      this.phoneNum = "";
      this.email = "";
      this.consentChecked = false;
    },
    openModalCallBack() {
      this.showDialog = true;
      this.name = "";
      this.phoneNum = "";
      this.email = "";
      this.consentChecked = false;
    },
    openModalCallBackMobile(){
      this.showDialogMobile = true;
      this.name = "";
      this.phoneNum = "";
      this.email = "";
      this.consentChecked = false;
    },
    closeDialogMobile() {
      this.showDialogMobile = false;
      this.name = "";
      this.phoneNum = "";
      this.email = "";
      this.consentChecked = false;
    },
    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      this.activeTextarea = null;
    },
  },
  computed: {
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "";
    },
    isValid() {
      return this.phoneNum && this.consentChecked;
    },
  },
};
</script>

<style scoped>
 .btn-contaiener {
    display: flex;
    justify-content: space-evenly;
  }
  .snackbar-btn {
    color: var(--03, #bcb648);
    font-family: Inter;
    font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.0178571429em;
      line-height: 1.25rem;
      margin-right: auto;
      padding: 14px 16px;
      text-align: initial;
    cursor: pointer;
  
  }
.text-warning {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}
.checkbox-container {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.custom-checkbox {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: white; /* Белый фон, когда не выбран */
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.custom-checkbox:checked + .checkmark {
  background-color: var(--03, #bcb648); /* Цвет при выборе */
}

/* .custom-checkbox:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */

.custom-checkbox:checked:hover + .checkmark {
  background-color: var(--03, #bcb648); /* Цвет при выборе и наведении */
}
.checkbox-container svg {
  width: 80%;
  height: 80%;
  flex-shrink: 0;
  stroke-width: 2px;
  stroke: var(--light-white, #fff);
  align-items: center;
  position: absolute;
  top: 2px;
  left: 2px;
}
.policy-text-mobile {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.btn-call-modal:disabled {
  background-color: grey; /* Серый цвет фона для неактивной кнопки */
  color: white; /* Цвет текста на неактивной кнопке (белый, чтобы контрастировать с серым фоном) */
}
.policy-container {
  margin-left: 80px;
  min-width: 280px;
}
.btn-call {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 231px;
}
.footer-text-underline {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 215.9%; /* 34.544px */
  text-decoration: underline;
}
.footer-bottom-margin {
  margin-bottom: 87px;
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.footer-span-special {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 215.9%;
  margin-left: 33px;
}
.footer-content-special {
  display: flex;
  flex-direction: column;
}
.footer-text-special {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 215.9%; */
  align-items: center;
  display: flex;
}
.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer-text {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 215.9%; /* 34.544px */
}
.footer-nav-img {
  width: 24px;
  height: 24px;
  margin-right: 9px;
}
.footer-logo-container {
  text-align: center;
}
.footer-logo {
  width: auto;
  height: auto;
  margin-top: 36px;
  margin-bottom: 36px;
}
.footer-main {
  background: var(--01, #603725);
  width: 100%;
}
.footer-info {
  width: 490px;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.call-back-container {
  width: 490px;
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start;
}
.footer-main-mobile {
  background: var(--01, #603725);
  width: 100%;
}
.footer-logo-container-mobile {
  text-align: center;
  margin-top: 14px;
}
.footer-logo-mobile {
  margin-top: 14px;
}
.footer-bottom-margin-mobile {
  width: 100%;
  padding: 16px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.footer-info-mobile {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-content-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer-text-mobile {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* 34.544px */
}
.footer-content-special-mobile {
  display: flex;
  flex-direction: column;
}
.footer-text-special-mobile {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 215.9%; */
  align-items: center;
  display: flex;
}
.footer-span-special-mobile {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 33px;
}
.policy-container-mobile {
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  text-align: center;
}
.footer-text-underline-mobile {
  color: var(--05, #dae3ea);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 215.9%; /* 34.544px */
  text-decoration: underline;
}
.btn-call-mobile {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 231px;
}
.call-back-container-mobile {
  max-width: 490px;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 33px;
  margin-bottom: 38px;
}
.name-input:focus {
  border-color: #dae3ea; /* Замените цвет на ваш желаемый цвет */
  outline: none;
}
.name-input {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--05, #dae3ea);
  background: var(--06, #fff);
  margin-top: 10px;
  resize: none;
  overflow-y: hidden;
  margin-top: 10px;
  /* height: fit-content; */
  font-size: 16px;
  padding: 10px;
  height: 45px;
}
.name > label {
  color: var(--07, #000);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
::v-deep .v-dialog {
  border-radius: 20px !important;
  background: var(--06, #fff) !important;
}
.application-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  margin-bottom: 30px;
}
.btn-call-modal {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  border-radius: 4px;
  background: var(--03, #bcb648);
  color: var(--06, #fff);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 30px auto;
}
.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 365px) {
  /* Если ширина экрана больше 768px, кнопки будут располагаться горизонтально */
  .btn-mobile {
    width: auto;
  }

  .distributor-picture-big-mobile {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .second-block-mobile {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .v-dialog {
    border-radius: 4px;
    margin: 0px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    outline: none;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}
@media screen and (max-width: 700px) {
  /* Если ширина экрана больше 768px, кнопки будут располагаться горизонтально */
  ::v-deep .v-card__title {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: inherit;
    align-items: baseline;
  }

  .v-dialog > .v-card > .v-card__subtitle,
  .v-dialog > .v-card > .v-card__text {
    padding: 0 18px 10px;
  }

  .btn-call-modal {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    border-radius: 4px;
    background: var(--03, #bcb648);
    color: var(--06, #fff);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin: 30px auto; */
    /* text-align: center; */
    width: 100%;
    margin-top: 31px;
    margin-bottom: 15px;
  }

  .v-dialog > .v-card > .v-card__title {
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    padding: 16px 24px 10px;
    padding-top: 13px;
    padding-right: 17px;
    padding-bottom: 10px;
    padding-left: 14px;
  }
  .checkbox-container {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    border: 1px solid black;
    background-color: white;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
}
@media screen and (min-width: 643px) and (max-width: 1053px) {
  .second-column-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .footer-bottom-margin-mobile {
    /* max-width: 330px; */
    max-width: 1263px;
    width: 100%;
    padding: 32px;
    /* margin-right: auto; */
    /* margin-left: auto; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 31px;
  }
  .policy-container-mobile {
    margin-top: 32px;
    align-items: flex-start;
    text-align: start;
  }
}
@media screen and (min-width: 1050px) and (max-width: 1263px) {
  .second-column-mobile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .footer-bottom-margin-mobile {
    /* max-width: 330px; */
    max-width: 1263px;
    width: 100%;
    padding: 12px;
    /* margin-right: auto; */
    /* margin-left: auto; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    margin-bottom: 81px;
  }
  .policy-container-mobile {
    margin-top: 0px;
    align-items: flex-start;
    text-align: start;
    margin-right: 27px;
  }
  .call-back-container-mobile {
    max-width: 490px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 98px;
  }
}
</style>
